import React from "react"
import { useParams } from "react-router-dom";

const Text = () => {
  const { message } = useParams()
  
  return (
    <div style={{size: "3em", 'text-align': 'center', height: '100vh', 'line-height': '100vh'}}>{message}</div>
  )
}

export default Text

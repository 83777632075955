import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  outer: {
    position: "absolute",
    bottom: "1vh",
    right: "1vw",
    border: "solid black 1px",
    "border-radius": "20px",
    background: "white",
    width: "60px",
    height: "30px",
    display: "flex",
    "justify-content": "space-around",
    "align-items": "center"
    
  },
  inner: {
    width: "20px",
    height: "20px",
    "border-radius": "20px",
  },
  on: {
    background: "lightgrey"
  },
  off: {
    background: "black"
  },
})
export default useStyles;

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Decoder from "./Decoder";
import Text from "./Text";
import React, { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    document.title = 'Rift Authenticator';
  }, []);
  return (
    <Router>
      <Switch>
        <Route path='/decoder/:uid' component={Decoder} />
        <Route path='/text/:message' component={Text} />
        <Route path='/' component={Decoder} />
      </Switch>
    </Router>
  )
}

export default App

import React, { useState, useEffect, useRef, useCallback } from "react"
import { useParams } from "react-router-dom";
import { ReactComponent as Target } from "./target.svg"
import useStyles from "./DecoderStyles"
import AliveBug from "./AliveBug"
import useSound from "use-sound"
import ding from "./ding.mp3"

const api = 'https://41ajq5iyg2.execute-api.us-west-2.amazonaws.com/test/' 
//const api = 'https://10.1.10.48:8080/invocations' 
const constraints = { 
    video: {
      facingMode: 'environment',
      width: 768, 
      aspectRatio: 4/3,
    }, audio: false };// Define constants

const send = (canvasRef, videoRef, uid, ip) => {
  return new Promise((resolve, reject) => {
    const canvas = canvasRef.current

    let screen_height = Math.max(window.screen.height, window.screen.width)
    let screen_width = Math.min(window.screen.height, window.screen.width)

    let ratio = (screen_height / videoRef.current.videoHeight) /
        (screen_width / videoRef.current.videoWidth) 

    let width = videoRef.current.videoWidth / ratio
    width = Math.floor(width * .9)
    console.log(width)
    console.log(videoRef.current.videoWidth)

    if (isNaN(width)) {
      setTimeout(() => resolve({type:'invalid', data:'null body', sorter:[]}), 250)
      return
    }

    canvas.height = width
    canvas.width = width
    const startx = Math.floor((videoRef.current.videoWidth - width) / 2)
    const starty = Math.floor((videoRef.current.videoHeight - width) / 2)
    //canvas.getContext("2d").drawImage(videoRef.current, 0, 0) //videoRef.current, startx, starty, 
    canvas.getContext("2d").drawImage(videoRef.current, startx, starty, 
                                      width, width, 0, 0, width, width)
    
    const rgb = canvas.getContext("2d").getImageData(0, 0, width, width).data.filter((_, i) => i % 4 !== 3)
    if ((rgb.reduce((a, b) => a + b) / rgb.length) < 40) {
        setTimeout(() => resolve({type:'invalid', data:'null body', sorter:[]}), 250)
        return
    }

    let send_uid = uid
    if (uid.includes("debug")) send_uid = uid.substring(6)

    canvas.toBlob(blob => {
        let ctx = canvas.getContext("2d")
        ctx.fillStyle = "black"
        ctx.fillRect(0, 0, width, width)
        if (blob === null) {resolve({type:'invalid', data:'null body', sorter:[]})} 
        else {
          let custom_attributes = send_uid + ',' + ip
          if(send_uid.includes("pattern")) custom_attributes = custom_attributes + ',' + send_uid.substring(8)
            
          fetch(api, {method: "POST", body: blob,
                    headers: {'X-Amzn-SageMaker-Custom-Attributes': custom_attributes,
                              'Content-Type': 'image/jpeg'}})
            .then(response => response.ok ? response.json().then(resolve)
                                          : response.text().then(reject))
            .catch(reject)
        }
    }, "image/jpeg")
  })
}

const orientationWatcher = () => {
  let body = document.body;
  body.classList = '';
  switch(window.orientation) {
    case 90:
      body.classList.add('rotation90');
      break;
    case -90:
      body.classList.add('rotation-90');
      break;
    default:
      body.classList.add('portrait');
      break;
  }
}

const Decoder = () => {
  const classes = useStyles()
  const videoRef = useRef()
  const canvasRef = useRef()
  const triggerRef = useRef()

  const [result, setResult] = useState('')
  const [streaming, setStreaming] = useState(false)
  //const toggle = React.useCallback(() => setStreaming(v => !v), []);
  
  const [frame, setFrame] = useState(0)
  const increment = useCallback(() => setFrame(v => v + 1), []);

  const [ip, setIP] = useState(false)

  const [playDing] = useSound(ding)

  let { uid } = useParams()
  if (window.location.toString().includes('pinzy.io')) uid = "pinzy.io"
  if (uid === undefined) uid = "test"

  useEffect(() => {
    //Need these three lines to run on ios/safari
    videoRef.current.setAttribute('autoplay', '');
    videoRef.current.setAttribute('muted', '');
    videoRef.current.setAttribute('playsinline', '');
    
    window.addEventListener("pageshow", e => {if (e.persisted) window.location.reload()})

    window.addEventListener('orientationchange', orientationWatcher);
    window.addEventListener('focus', () => videoRef.current.play());
    window.addEventListener('blur', () => setStreaming(false));
    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        videoRef.current.srcObject = stream
        videoRef.current.addEventListener('play', () => {setTimeout(() => setStreaming(true), 100)})
        //setStreaming(true)
        videoRef.current.pause()
        videoRef.current.play().then(console.log).catch(console.log)
      })
      .catch(console.log)
    fetch('https://api.ipify.org/')
      .then(r => r.text())
      .then(setIP)
  }, [])

  useEffect(() => {
      triggerRef.current.click()
      if(streaming) {
        setFrame(0)
        setResult('')
      }
  }, [streaming])

  const click = () => {
    if (streaming) {
      increment()
      send(canvasRef, videoRef, uid, ip)
        .then(result => {
          console.log(result)
          if (typeof(result) === 'string') result = JSON.parse(result)
          if (result.type !== 'invalid') {
            //setResult(result.data)
            //setFrame(null)
            setStreaming(false)
            if (result.type !== 'error') playDing()
            if (result.type === 'error') {alert(result.data)}
            else if (result.type === 'text') {window.location.pathname='text/' + result.data}
            else if (result.type === 'url' || result.type === 'image') {
              if (result.data.startsWith('http')) {window.location.href = result.data}
              else {window.location.href = 'http://' + result.data}
            }
            triggerRef.current.click()
          } else {
            console.log(result.sorter)
            const models = ['hearts_collage', 'vines', 'globes', 
                      'floral', 'weave', 'camo', 'dots', 'damascus', 'new_globes',
                      'dogs_silhouette', 'birdies', 'floral_bees']
            const pretty = result.sorter.map((v, i) => 
              `${models[i]}: ${v.toString().slice(0,4)}\n`).join('')
            setResult(pretty)
            setTimeout(() => triggerRef.current.click(), 1)
          }
        })
        .catch(err => {
          console.error(err)
          alert('backend error')
          setFrame(null)
          setStreaming(false)
        })
    } 
  }

  //<WipeButton className={ip === false ? classes.hidden : classes.buttons} wipe={streaming} onClick={toggle} first="Start" second="Stop"/>
  return (
    <main>
      <button className={classes.hidden} ref={triggerRef} onClick={click}/>
      <canvas className={classes.hidden} ref={canvasRef} />
      <video className={classes.video} ref={videoRef} autoPlay="" muted="" playsinline="" />
      <Target className={`${classes.target}`}/>
      <div className={uid.includes("debug") ? classes.result : classes.hidden}>{result} frame: {frame}</div>
      <AliveBug oddFrame={frame % 2 == 1} />
    </main>
  )
}

export default Decoder

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    target: {
      top: "50vw",
      width: "90%",
      left: "5%",
      position: "absolute",
      transform: "translateY(-50%)",
    },
    video: {
      "object-fit": "cover",
      "overflow": "hidden",
      height: "100vw",
      width: "100vh",
      position: "absolute",
    },
    "@media (orientation: portrait)":{
      video: {
        height: "100vh",
        width: "100vw",
      },
      target: {
        top: "50vh",
      }
    },
    hidden: {
      display: "none",
    },
    buttons: {
      "font-size": "3em",
      margin: "auto",
      "margin-top": "50px",
    },
    result: {
      "font-size": "1em",
      "position": "absolute",
      "color": "black",
      "background": "white",
    },
});
export default useStyles;

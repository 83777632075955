import useStyles from "./AliveBugStyles";

const AliveBug = ({ oddFrame }) => {
  const classes = useStyles();

  return (
    <div className={classes.outer}>
      <div className={`${classes.inner} ${oddFrame ? classes.on : classes.off}`}></div>
      <div className={`${classes.inner} ${oddFrame ? classes.off : classes.on}`} />
    </div>
  );
};

export default AliveBug;
